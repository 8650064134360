export default [
  {
    text: 'Company',
    links: [
      {
        text: 'Home',
        to: '/'
      },
      {
        text: 'Custom Designs',
        to: '/custom-designs'
      },
      {
        text: 'Testimonials',
        to: '/testimonials'
      },
      {
        text: 'Club Stores',
        to: '/stores'
      },
      {
        text: 'Contact',
        to: '/contact'
      }
    ]
  },
  {
    links: [
      {
        text: 'AFL',
        to: '/sports/afl'
      },
      {
        text: 'Athletics',
        to: '/sports/athletics'
      },
      {
        text: 'Basketball',
        to: '/sports/basketball'
      },
      {
        text: 'Cricket',
        to: '/sports/cricket'
      },
      {
        text: 'Hockey',
        to: '/sports/hockey'
      },
      {
        text: 'Netball',
        to: '/sports/netball'
      },
      {
        text: 'Rugby',
        to: '/sports/rugby'
      },
      {
        text: 'Soccer',
        to: '/sports/soccer'
      },
      {
        text: 'Schools',
        to: '/sports/schools'
      },
      {
        text: 'Corporate',
        to: '/sports/corporate'
      }
    ],
    text: 'Sports'
  },
  {
    links: [
      {
        text: 'About',
        to: '/about'
      },
      {
        text: 'Media',
        to: '/media'
      },
      {
        text: 'Reviews',
        to: '/testimonials'
      }
    ],
    text: 'About Us'
  }
]
