import * as React from 'react'

import {
  SocialMedia,
  Footer as StyledFooter,
  FooterLink,
  FooterList,
  FooterInfo,
  StyledFlex
} from './styles'
import Logo from '../Logo'

import Instagram from 'react-icons/lib/fa/instagram'
import Facebook from 'react-icons/lib/fa/facebook'
import Google from 'react-icons/lib/fa/google-plus'
import Twitter from 'react-icons/lib/fa/twitter'

import footerLinks from './footer'

const Footer: React.SFC = () => (
  <StyledFooter>
    <FooterInfo>
      <Logo color="primary" />
      <p>
        © 2018 GFit Sportswear. <br />
        All rights reserved.
      </p>
      <SocialMedia>
        <a target="_blank" href="https://instagram.com/gfitwear">
          <Instagram />
        </a>
        <a target="_blank" href="https://facebook.com/gfitwear">
          <Facebook />
        </a>
      </SocialMedia>
    </FooterInfo>
    <StyledFlex>
      {footerLinks.map(item => (
        <FooterList key={item.text}>
          <span>{item.text}</span>
          {item.links.map(link => (
            <FooterLink key={link.text} to={link.to || '#'}>
              {link.text}
            </FooterLink>
          ))}
        </FooterList>
      ))}
    </StyledFlex>
  </StyledFooter>
)

export default Footer
