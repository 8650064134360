import * as React from 'react'
import { theme } from '../utils/theme'

interface HeaderProps {
  color: 'primary' | 'seconday' | 'tertiary' | 'dark' | 'light'
}

const Logo = ({ color, ...rest }: HeaderProps) => (
  <svg
    width="74"
    height="26"
    viewBox="0 0 74 26"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fill={theme.colors[color]} fillRule="evenodd">
      <path d="M58 0H10.6S6.4.8 5 5C3.7 9 0 21.5 0 21.5s-.6 3 2.4 4h16.8s3.8 0 4.6-2.4l4.5-13.8-16.5-.1 5.1 4.4h4.3l-2.1 6.6H6.5l4.3-14.5h21.3L25.8 26H32L35 15.6h6.1l2-6.5H37l1-3.3h14.3L58 0z" />
      <path d="M45.1 10L40 26h6l5-16zM52.8 9.7l-1.8 6h4.1L51.6 26h6l3.1-10.2h6.5l6.8-6H62.9L64.4 4l-6.6 2-1 3.7z" />
    </g>
  </svg>
)

export default Logo
