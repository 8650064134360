export default [
  {
    text: 'Home',
    to: '/',
  },
  {
    dropdown: [
      {
        text: 'AFL',
        to: '/sports/afl',
      },
      // {
      //   text: 'Athletics',
      //   to: '/sports/athletics'
      // },
      { text: 'Touch', to: '/sports/touch-football' },
      {
        text: 'Baseball',
        to: '/sports/baseball',
      },
      {
        text: 'Basketball',
        to: '/sports/basketball',
      },
      {
        text: 'Cricket',
        to: '/sports/cricket',
      },
      {
        text: 'Hockey',
        to: '/sports/hockey',
      },
      {
        text: 'Netball',
        to: '/sports/netball',
      },
      {
        text: 'Rugby League',
        to: '/sports/rugby-league',
      },
      {
        text: 'Rugby Union',
        to: '/sports/rugby-union',
      },
      {
        text: 'Soccer',
        to: '/sports/soccer',
      },
      {
        text: 'Schools',
        to: '/sports/schools',
      },
      {
        text: 'Corporate',
        to: '/sports/corporate',
      },
      {
        text: 'Hi Vis',
        to: '/sports/hi-vis',
      },
    ],
    width: '180px',
    text: 'Sports',
  },
  {
    // dropdown: [
    //   {
    //     text: 'About GFit',
    //     to: '/about'
    //   },
    //   {
    //     text: 'Media',
    //     to: '/media'
    //   },
    //   {
    //     text: 'Reviews',
    //     to: '/testimonials'
    //   }
    // ],
    // width: '150px',
    text: 'About',
    to: '/about',
  },
  {
    text: 'Custom Designs',
    to: '/custom-designs',
  },
  {
    text: 'Testimonials',
    to: '/testimonials',
  },
  {
    text: 'Club Shops',
    to: '//clubshop.gfitwear.com',
  },
  {
    text: 'Gallery',
    to: '/gallery',
  },
  {
    text: 'Contact',
    to: '/contact',
  },
]
